var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row offers mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('h3', {
    staticClass: "h3 text-center p-2"
  }, [_vm._v("Premium Brands Offers")]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(" Count : "), _c('strong', {
    staticClass: "pl-2"
  }, [_vm._v(" " + _vm._s(_vm.totalItemRows ? _vm.totalItemRows.count : 0) + " ")])])])]), _c('b-table', {
    attrs: {
      "sticky-header": "700px",
      "responsive": "xl",
      "show-empty": "",
      "busy": _vm.isLoading,
      "isLoading": _vm.isLoading,
      "fields": _vm.fields,
      "hover": "",
      "items": _vm.items
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function (row) {
        return [_c('div', [row.item.item_status == 'ready for consume' ? _c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v(" " + _vm._s(row.item.item_status) + " ")]) : _c('span', {
          staticClass: "badge badge-danger"
        }, [_vm._v(" " + _vm._s(row.item.item_status) + " ")])])];
      }
    }, {
      key: "cell(Brand)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.brand_name) + " ")];
      }
    }, {
      key: "cell(item_type)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.item_type) + " ")];
      }
    }, {
      key: "cell(delete)",
      fn: function (row) {
        return [_c('button', {
          staticClass: "btn btn-sm btn-danger",
          on: {
            "click": function ($event) {
              return _vm.actionDelete(row.item);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-trash"
        })])];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  }, [_vm._v(" > ")])], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalItemRows.count,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }