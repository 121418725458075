<template>
  <div class="row offers mt-2">
    <b-col lg="12">
      <div class="card">
        <h3 class="h3 text-center p-2">Premium Brands Offers</h3>
        <div class="card-body">
          <div class="row justify-content-end">
            <div class="col-sm-auto">
              <label for=""> Count :
                <strong class="pl-2">
                  {{ totalItemRows ? totalItemRows.count : 0 }}
                </strong>
              </label>
            </div>
          </div>
          <b-table 
            sticky-header="700px"
            responsive="xl"
            show-empty
            :busy="isLoading"
            :isLoading="isLoading"
            :fields="fields"
            hover 
            :items="items">
            >
            <template #cell(No)="row">
              {{ row.index+1}}
            </template>
            <template #cell(status)="row">
              <div>
                <span v-if="row.item.item_status =='ready for consume'" class="badge badge-success">
                  {{ row.item.item_status }}
                </span>
                <span v-else class="badge badge-danger"> 
                  {{ row.item.item_status }}
                </span>
              </div>
            </template>
            <template #cell(Brand)="row">
              {{ row.item.brand_name }}
            </template>
            <template #cell(item_type)="row">
              {{ row.item.item_type }}
            </template>
            <template #cell(delete)="row">
              <button class="btn btn-sm btn-danger" @click="actionDelete(row.item)">
                <i class="fa fa-trash"></i>
              </button>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItemRows.count"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </b-col>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters, mapActions } from "vuex";
import API from "../../plugins/http";
export default {
  name: "PremiumOffers",
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      fields: [
        {
          key:'No',
          label: 'No',
          thStyle: { width: "60px" },
          tdStyle: { width: "60px" }
        },
        { key:'id', label: 'Brand ID'},
        { key: 'name', label: 'Item Name'},
        { key:'Brand', label:'Brand'},
        { key:'item_type', label:'Item Type'},
        { key:'status', label:'Status'},
        { key:'delete', label:'Delete'}
      ]
    };
  },
  computed: {
    ...mapGetters("offerspremium", { items: "items" }),
    ...mapState({
      isLoading: (state) => state.offerspremium.isLoading,
      isError: (state) => state.offerspremium.isError,
      totalItemRows: (state) => state.offerspremium.totalItemRows,
      ErrorMessage: (state) => state.offerspremium.ErrorMessage,
    }),
  },
  mounted() {
    this.actionGetOffers();
  },
  watch: {
    currentPage: function() {
      this.actionGetOffers();
    },
  },
  methods: {
    ...mapActions("offerspremium", ["fetchOffersItemsByOfferId","deleteBrandOffer"]),
    actionGetOffers() {
      let payload = {
        id: this.$route.params.id,
        page: this.currentPage,
        limit: this.perPage,
      };

      this.fetchOffersItemsByOfferId(payload);
    },
    formatDate(tgl) {
      return moment(tgl).format("DD MMM YY hh:mm");
    },
    formatDateValid(from, to) {
      return (
        moment(from).format("DD MMM YY") +
        " - " +
        moment(to).format("DD MMM YY")
      );
    },
    OfferType(key) {
      if (key == 1) {
        return "Single";
      } else if (key == 2) {
        return "Subscriptions";
      } else if (key == 3) {
        return "Bundle";
      } else if (key == 4) {
        return "Buffet";
      }
    },
    OfferStatus(key) {
      if (key == 1) {
        return "New";
      } else if (key == 2) {
        return "Waitting For Review";
      } else if (key == 3) {
        return "In Review";
      } else if (key == 4) {
        return "Rejected";
      } else if (key == 5) {
        return "Approved";
      } else if (key == 6) {
        return "Prepare for sale";
      } else if (key == 7) {
        return "Ready For Sale";
      } else if (key == 8) {
        return "Not For Sale";
      }
    },
    actionDelete(item) {
       this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              let payload = {
                brand_id: item.brand_id,
                offer_id: item.offer_id,
              }
              let info = await API.delete('offers-premium/offer-brands/'+ item.brand_id,payload)
              if (info) {
                this.$swal.fire({
                  toast: "true",
                  position: "top-end",
                  icon: "success",
                  title: "delete success",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.actionGetOffers();
              } else {
                this.$swal.fire("Error!", info.data.message, "error");
              }
            } catch (error) {
              console.log(error.response);
               this.$swal.fire("Error!", "Server Error", "error");
            }
          }
        });
    }
  },
};
</script>

<style scoped>
</style>
